import { ButtonLink } from '@applyboard/crystal-ui';

type ApplicationLetterLinkProps = { label: string; link: string };
export function ApplicationLetterLink({ label, link }: ApplicationLetterLinkProps) {
  return (
    <ButtonLink
      aria-label={label}
      emphasis="transparent"
      href={link}
      intent="primary"
      size="lg"
      width="fill"
      mode="override_user_choice_and_open_in_new_tab"
    >
      {label}
    </ButtonLink>
  );
}
