import React from 'react';
import {
  DocumentOutlineIcon,
  // ClockOutlineIcon,
  DocumentCheckOutlineIcon,
  CheckMarkOutlineIcon,
  CloseOutlineIcon,
} from '@applyboard/ui-icons';
import { Flex, Text, Card as FrameCard, Avatar, Tag } from '@applyboard/crystal-ui';
import { TruncatedButtonLink } from '~/components/uie-fix/TruncatedButtonLink';
import { Attachment } from '~/pages/ApplicationDetails/data/useApplication';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import moment from 'moment';
import { Card } from './Card';

function DecisionLetterCard(attachmentDocument: Attachment) {
  function getDocumentTile(attachmentDocument: Attachment) {
    if (attachmentDocument.type.code === 'REJECTION_LETTER') {
      return 'Decline Letter';
    } else {
      return 'Initial Offer Letter';
    }
    // else if (attachmentDocument.type.code === 'FINAL_OFFER_LETTER'){
    //   return 'Final Offer Letter';
    // } else if (attachmentDocument.type.code === 'WAITLIST_LETTER'){
    //   return 'Waitlist Letter';
    // }
  }

  function getDocumentAvatar(attachmentDocument: Attachment) {
    if (attachmentDocument.type.code === 'REJECTION_LETTER') {
      return (
        <Avatar
          aria-label="rejection-letter"
          emphasis="highlighted"
          icon={CloseOutlineIcon}
          intent="negative"
          size="md"
        />
      );
    } else {
      return (
        <Avatar
          aria-label="pre-offer-letter"
          emphasis="highlighted"
          icon={CheckMarkOutlineIcon}
          intent="positive"
          size="md"
        />
      );
    }
    //  else if( attachmentDocument.type.code === 'FINAL_OFFER_LETTER'){
    //   return (
    //     <Avatar
    //       aria-label="final-offer-letter"
    //       emphasis="highlighted"
    //       icon={CheckMarkOutlineIcon}
    //       intent="positive"
    //       size="md"
    //     />
    //   );
    // }
    // else if( attachmentDocument.type.code === 'WAITLIST_LETTER'){
    // return (
    //     <Avatar
    //        aria-label="waitlist-letter"
    //       emphasis="highlighted"
    //        icon={ClockOutlineIcon}
    //       intent="secondary"
    //        size="md"
    //     />
    //    );
    //   }
  }
  return (
    <FrameCard>
      <Flex direction="column" gap={8}>
        <Flex direction="row" justify="between" gap={8}>
          <Flex.Item>
            <Flex direction="row" align="center" justify="center" gap={6}>
              <Flex.Item>{getDocumentAvatar(attachmentDocument)}</Flex.Item>
              <Flex.Item>
                <Text variant="titleS" contrast="high" intent="secondary">
                  {getDocumentTile(attachmentDocument)}
                </Text>
              </Flex.Item>
              <Flex.Item>
                <Tag intent="positive" size="sm">
                  {`Issued on ${moment(attachmentDocument.updatedAt).format('MMM DD, YYYY')}`}
                </Tag>
              </Flex.Item>
            </Flex>
          </Flex.Item>
          <Flex.Item>
            <TruncatedButtonLink
              aria-label={`Download ${attachmentDocument.fileName} document`}
              emphasis="highlighted"
              href={attachmentDocument.downloadUrl!}
              intent={attachmentDocument.validationStatus === 'VALID' ? 'positive' : 'primary'}
              size="lg"
              leadIcon={DocumentCheckOutlineIcon}
              width="hug"
              mode="override_user_choice_and_open_in_new_tab"
            >
              {attachmentDocument.fileName}
            </TruncatedButtonLink>
          </Flex.Item>
        </Flex>

        {/* {attachmentDocument.type.code === 'FINAL_OFFER_LETTER' && (
          <Flex direction="row" gap={36} justify="start">
            <Flex.Item>
              <Text variant="labelM" intent="secondary">
                Offer Status
              </Text>
              <Tag intent="positive" size="sm">
                Student Accepted Offer
              </Tag>
            </Flex.Item>
            <Flex.Item>
              <Text variant="labelM" intent="secondary">
                Accepted On
              </Text>
              <Tag intent="secondary" size="sm">
                Dec 31, 2023
              </Tag>
            </Flex.Item>
          </Flex>
        )} */}

        {/* {attachmentDocument.type.code === 'REJECTION_LETTER' && (
          <Flex direction="row" gap={36} justify="start">
            <Flex.Item>
              <Text variant="labelS" intent="secondary">
                School Declination Reason
              </Text>
              <Text variant="labelM" intent="secondary" contrast="low">
                Decided to purse admission at another University
              </Text>
            </Flex.Item>
          </Flex>
        )} */}
      </Flex>
    </FrameCard>
  );
}
export function DecisionLetters() {
  const {
    application: { preOfferLetter, rejectionLetter },
  } = useAppProcessing();
  return (
    <Card hideChevron id="decisionLetters" icon={DocumentOutlineIcon} title="Decision Letters">
      <Flex gap={8} direction="column">
        {preOfferLetter && <Flex.Item>{DecisionLetterCard(preOfferLetter)}</Flex.Item>}
        {rejectionLetter && <Flex.Item>{DecisionLetterCard(rejectionLetter)}</Flex.Item>}
      </Flex>
    </Card>
  );
}
