import { useAppProcessing, useAdditionalDocumentRequest } from '~/pages/ApplicationDetails/context';

import { ActionGroup } from './ActionGroup';
import { NoticeContextProvider } from './useNotice';
import { DeclineDialog } from './Decline/DeclineDialog';
import { PreOfferDialog } from './IssuePreOffer/PreOfferDialog';
import { AdditionalDocumentRequest } from './AdditionalDocumentRequest';
import { PassScreeningDialog, usePassScreeningActivity } from './passScreening';
import { ApplicationLetterLink } from './Links/ApplicationLetterLink';
import { DeferralRequestDialog } from './DeferralRequest/DefferalRequestDialog';

export function ApplicationProcessing() {
  const additionalDocumentRequest = useAdditionalDocumentRequest();
  const { activeProcess, startProcess, application } = useAppProcessing();
  const { passScreening } = usePassScreeningActivity();

  function onAction(action: string) {
    switch (action) {
      case 'DECLINED':
        return startProcess('decline');
      case 'INITIAL_OFFER':
        return startProcess('issuePreOffer');
      case 'READY_FOR_ASSESSMENT':
        return passScreening();
      case 'DOCUMENTS_REQUESTED_IN_ASSESSMENT':
      case 'DOCUMENTS_REQUESTED_IN_SCREENING':
        return additionalDocumentRequest.enableFeature();
    }
  }

  if (activeProcess === 'additionalDocumentRequest') return <AdditionalDocumentRequest />;

  return (
    <NoticeContextProvider>
      {activeProcess === 'decline' && <DeclineDialog />}
      {activeProcess === 'issuePreOffer' && <PreOfferDialog applicationId={application.id} />}
      {activeProcess === 'deferralRequestPreOffer' && (
        <PreOfferDialog applicationId={application.id} patchDeferralRequest />
      )}
      {activeProcess === 'passScreening' && <PassScreeningDialog />}
      {activeProcess === 'deferralRequest' && <DeferralRequestDialog />}
      {application.preOfferLetter?.downloadUrl && application.status.code === 'INITIAL_OFFER' && (
        <ApplicationLetterLink link={application.preOfferLetter.downloadUrl} label="View Pre-Offer Letter" />
      )}
      {application.rejectionLetter?.downloadUrl && application.status.code === 'DECLINED' && (
        <ApplicationLetterLink link={application.rejectionLetter.downloadUrl} label="View Decline Letter" />
      )}
      {application.status.actionGroups.map((actionGroup) => (
        <ActionGroup key={actionGroup.title} actionGroup={actionGroup} onAction={onAction} />
      ))}
    </NoticeContextProvider>
  );
}
