import { BackButton } from '~/components/BackButton';
import { Flex, GridAlpha } from '@applyboard/crystal-ui';
import { ApplicationDetailsTabs } from '../components/ApplicationDetailsTabs/ApplicationDetailsTabs';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

import {
  ProgramDetails,
  ApplicationDetailsHeading,
  StatusAndCitizenship,
  ContactInformation,
  ApplicantInformation,
  EducationHistory,
  LanguageProficiency,
  OtherRequiredDocuments,
  ApplicationProcessing,
  CardControlActions,
  DecisionLetters,
  // UploadAdditionalDocuments,
  // processing
  AdditionalDocumentNotice,
  DeferralRequestNotice,
} from './../components';

type DesktopLayoutProps = { programDetailsLineSplit: boolean };
export function DesktopLayout(props: DesktopLayoutProps) {
  const { application } = useAppProcessing();

  function applicationDetailsLayout() {
    return (
      <Flex gap={8} align="stretch" direction="column">
        <GridAlpha columns="repeat(2,1fr)" areas={['left right']} columnGap={10}>
          <GridAlpha.Item areaName="left">
            <Flex gap={8} direction="column">
              <ApplicantInformation />
              <ContactInformation />
              <StatusAndCitizenship />
            </Flex>
          </GridAlpha.Item>
          <GridAlpha.Item areaName="right">
            <Flex gap={8} direction="column">
              <EducationHistory />
              <LanguageProficiency />
              <OtherRequiredDocuments />
            </Flex>
          </GridAlpha.Item>
        </GridAlpha>
      </Flex>
    );
  }
  return (
    <div aria-label="desktop" style={{ marginBottom: '10px' }}>
      <Flex pt={10} pb={2} px={8} gap={6} direction="column">
        <BackButton title="Applications List" href="/applications" />
      </Flex>
      <Flex px={8} gap={6} direction="column">
        <Flex justify="between" align="center" gap={2}>
          <ApplicationDetailsHeading />
          <ApplicationProcessing />
        </Flex>
        <DeferralRequestNotice />
        <AdditionalDocumentNotice />
        <ProgramDetails lineSplit={props.programDetailsLineSplit} />
        <CardControlActions />
        {/*TODO: update this when we add the new status */}
        {application.status.code === 'DECLINED' || application.status.code === 'INITIAL_OFFER' ? (
          <ApplicationDetailsTabs
            submittedApplicationDetails={applicationDetailsLayout()}
            applicationDetails={
              <Flex gap={8} align="stretch" direction="column">
                <DecisionLetters />
                {/* <UploadAdditionalDocuments /> */}
              </Flex>
            }
          />
        ) : (
          applicationDetailsLayout()
        )}
      </Flex>
    </div>
  );
}
