import type { DeepStringifyDates } from '@ab/ams-school-backend-utils/src/json-api/types';

// Application
import type { HttpResponse as GetApplicationHttpResponse } from '../../../src/routes/private/v1/applications/{id}/GET/types';
import type { HttpResponse as SpaPropsHttpResponse } from '../../../src/routes/private/v1/spa_props/GET/types';
import type { HttpResponse as ListApplicationsHttpResponse } from '../../../src/routes/private/v1/applications/GET/types';
import type { HttpResponse as ApplicationsPagePropsHttpResponse } from '../../../src/routes/private/v1/applications/page_props/GET/types';
import type {
  HttpRequest as PatchApplicationHttpRequestBody,
  HttpResponse as PatchApplicationHttpResponse,
} from '../../../src/routes/private/v1/applications/{id}/PATCH/types';
import type { ApplicationResource as BffApplicationResource } from '../../../src/resources/applications/types';

export type { ApplicationSortableFields } from '../../../src/resources/applications/types';
export type ApplicationResource = BffApplicationResource;
export type applicationTags = BffApplicationResource['attributes']['tags'][number];
export type Application = BffApplicationResource['attributes'] & { id: string };
export type Attachment = BffApplicationResource['attributes']['supportingDocuments'][number];

export type GetApplicationResponse = GetApplicationHttpResponse['body'];
export type SpaPropsResponse = SpaPropsHttpResponse['body'];
export type PatchApplicationResponse = PatchApplicationHttpResponse['body'];
export type ApplicationsPagePropsResponse = ApplicationsPagePropsHttpResponse['body'];
export type PatchApplicationRequestBody = PatchApplicationHttpRequestBody['body'];
export type ListApplicationsResponse = ListApplicationsHttpResponse['body'];

// Letter Preview
import type {
  HttpRequest as GetPreOfferLetterPreviewHttpRequestBody,
  HttpResponse as GetPreOfferLetterPreviewHttpResponse,
} from '../../../src/routes/private/v1/applications/{id}/pre_offer_letter_preview/POST/types';
export type GetPreOfferLetterPreviewRequestBody = DeepStringifyDates<GetPreOfferLetterPreviewHttpRequestBody['body']>;
export type GetPreOfferLetterPreviewResponse = DeepStringifyDates<GetPreOfferLetterPreviewHttpResponse['body']>;

import type {
  HttpRequest as GetRejectionLetterPreviewHttpRequestBody,
  HttpResponse as GetRejectionLetterPreviewHttpResponse,
} from '../../../src/routes/private/v1/applications/{id}/rejection_letter_preview/POST/types';
export type GetRejectionLetterPreviewRequestBody = GetRejectionLetterPreviewHttpRequestBody['body'];
export type GetRejectionLetterPreviewResponse = GetRejectionLetterPreviewHttpResponse['body'];

// Programs
import type { HttpResponse as ListProgramsHttpResponse } from '../../../src/routes/private/v1/programs/GET/types';
export type { ProgramResource } from '../../../src/resources/programs/types';
export type ListProgramsResponse = ListProgramsHttpResponse['body'];

// Application Admission Conditions
import type { HttpResponse as ListApplicationAdmissionConditionsResponses } from '../../../src/routes/private/v1/admission_conditions/GET/types';
export type { ApplicationAdmissionConditionResource } from '../../../src/resources/applicationAdmissionConditions/types';
export type ListApplicationAdmissionConditionsResponse = ListApplicationAdmissionConditionsResponses['body'];

// Application Rejection Reasons
import type { HttpResponse as ListApplicationRejectionReasonses } from '../../../src/routes/private/v1/rejection_reasons/GET/types';
export type { ApplicationRejectionReasonResource } from '../../../src/resources/applicationRejectionReasons/types';
export type ListApplicationRejectionReasons = ListApplicationRejectionReasonses['body'];

// Additional Document Request Types
export type {
  AdditionalDocumentsRequest,
  AdditionalDocumentsRequestResource,
} from '../../../src/resources/applications/types';

import type {
  HttpRequest as PostAdditionalDocumentHttpRequest,
  HttpResponse as PostAdditionalDocumentHttpRequestResponse,
} from '../../../src/routes/private/v1/applications/{id}/additional_document_requests/POST/types';
export type { ApplicationAdditionalDocumentsRequestResource } from '../../../src/routes/private/v1/applications/{id}/additional_document_requests/POST/types';
export type PostAdditionalDocumentRequestBody = PostAdditionalDocumentHttpRequest['body'];
export type PostAdditionalDocumentRequestResponse = PostAdditionalDocumentHttpRequestResponse['body'];

// reports
export type { ReportResource } from './../../../src/resources/reports/types';
import type { HttpResponse as GetApplicationReportsHttpResponse } from './../../../src/routes/private/v1/reports/applications/GET/types';
export type GetApplicationReportsResponse = GetApplicationReportsHttpResponse['body'];

// Deferral Request Type
export type { DeferralRequest } from '../../../src/resources/applications/deferralRequest/types';

import type {
  HttpRequest as PatchDeferralHttpRequestBody,
  HttpResponse as PatchDeferralHttpRequestResponse,
} from '../../../src/routes/private/v1/applications/{id}/deferral_request/PATCH/types';
export type PatchDeferralRequestBody = PatchDeferralHttpRequestBody['body'];
export type PatchDeferralRequestResponse = PatchDeferralHttpRequestResponse['body'];

// Language Proficiency
// we cannot expose applications-types-lib to the frontend, there are references to backend-utils in types-lib, which will crash the frontend
// export { TestType, HasLanguageProficiencyCodes } from 'applications-types-lib';
export enum TestType {
  IELTS = 'IELTS',
  PTE = 'PTE',
  TOEFL = 'TOEFL',
  OTHER = 'OTHER',
}

export enum HasLanguageProficiencyCodes {
  HAVE = 'HAVE',
  DONT_NEED = 'DONT_NEED',
  WILL_HAVE = 'WILL_HAVE',
}
