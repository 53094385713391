import { Flex, Tag, Heading } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';

export function ApplicationDetailsHeading() {
  const { application } = useAppProcessing();

  return (
    <Flex direction="column">
      <Flex pl={4} justify="between" align="center">
        <Flex align="center" gap={4} pb={2}>
          <Heading align="start" contrast="high" level={1} variant="headlineM" intent="secondary">
            Application Details
          </Heading>
          <Tag icon="fill" intent={application.status.intent} size="md">
            {application.status.label}
          </Tag>
        </Flex>
      </Flex>
    </Flex>
  );
}
