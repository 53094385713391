import React from 'react';
import {
  CheckMarkOutlineIcon,
  ChevronDownOutlineIcon,
  CloseOutlineIcon,
  DocumentCheckOutlineIcon,
  DocumentXOutlineIcon,
  QuestionOutlineIcon,
} from '~/icons';
import { IconType } from '~/utils';
import { Button, MenuButton } from '@applyboard/crystal-ui';
import { ApplicationStatus } from '../../data/useApplication';

type ActionGroup = ApplicationStatus['actionGroups'][number];
type Action = ActionGroup['actions'][number];

type ActionGroupProps = {
  actionGroup: ActionGroup;
  onAction: (status: ApplicationStatus['code']) => Promise<unknown> | unknown;
};
export function ActionGroup(props: ActionGroupProps) {
  const [open, setOpen] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const { actionGroup, onAction } = props;

  function onButtonClick(action: Action) {
    setIsBusy(true);
    Promise.all([onAction(action.nextStatus)])
      .catch(() => null)
      .finally(() => setIsBusy(false));
  }

  return (
    <MenuButton onOpenChange={(value) => setOpen(value)} open={open || isBusy} alignment="right">
      <MenuButton.Trigger>
        <Button
          intent="primary"
          trailIcon={ChevronDownOutlineIcon}
          loading={isBusy}
          width={{ xs: 'fill', sm: 'fill', md: 'hug' }}
          onClick={() => setOpen(true)}
        >
          {actionGroup.title}
        </Button>
      </MenuButton.Trigger>
      {actionGroup.actions.map((action) => (
        <MenuButton.Button
          key={action.nextStatus}
          id={action.nextStatus}
          disabled={isBusy}
          icon={IconsMapping[action.nextStatus]}
          onClick={() => onButtonClick(action)}
        >
          {action.label}
        </MenuButton.Button>
      ))}
    </MenuButton>
  );
}

// TODO: A tech-debt here, we should somehow link the status to proper icon in BFF
const IconsMapping: Partial<Record<ApplicationStatus['code'], IconType | undefined>> = {
  DECLINED: CloseOutlineIcon,
  INITIAL_OFFER: DocumentXOutlineIcon,
  READY_FOR_ASSESSMENT: CheckMarkOutlineIcon,
  READY_FOR_SCREENING: DocumentCheckOutlineIcon,
  DOCUMENTS_REQUESTED_IN_ASSESSMENT: QuestionOutlineIcon,
  DOCUMENTS_REQUESTED_IN_SCREENING: QuestionOutlineIcon,
  DEFERRAL_REQUESTED_IN_ASSESSMENT: undefined,
  DEFERRAL_REQUESTED_IN_INITIAL_OFFER: undefined,
  DEFERRAL_REQUESTED_IN_SCREENING: undefined,
};
